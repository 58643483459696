<template>
    <div class="fond w-screen h-screen flex justify-center items-center flex-col">
        <div class="w-full md:w-2/4 lg:w-2/4 text-white text-center p-3">
            <h1 class="font-bold text-3xl my-4">A qui s'adresse la chiropraxie ?</h1>
            <p class="text-xl font-light my-3">"A toutes les personnes possédant une colonne vertébrale"</p>
            <p class="my-3">La chiropraxie est la 1ère profession de santé manuelle dans le monde 
                et la 3ème profession de santé, après la médecine et le domaine dentaire. 
                Cette thérapie naturelle (non médicamenteuse) est reconnue par l'Organisation 
                Mondiale de la Santé.</p>
            <button-view/>
        </div>
    </div>
    <div class="w-screen flex items-center py-12">
        <div class="my-8">
            <h2 class="text-black text-center font-extrabold text-3xl mx-3" style="font-family: 'Satisfy', cursive;">La chiropraxie s'adresse à tous !</h2>
            <div class="flex flex-col md:flex-row lg:flex:row flex-wrap justify-center">
                <div class="card">
                    <img src="../assets/images/sportif.jpg" alt="travail">
                    <h3>Accompagnement du sportif</h3>
                    <p>La chiropraxie améliore les performances articulaires et musculaires. 
                        Elle prévient les blessures en identifiant les problèmes de posture ou d'appui.</p>
                </div>
                <div class="card">
                    <img src="../assets/images/travail.jpg" alt="travail">
                    <h3>La chiropraxie au travail</h3>
                    <p>Mal de dos après une journée au travail ? Mal au cou ? Maux de tête ? 
                        Contractures liées au stress ? Votre chiropracteur vous donne des conseils 
                        afin d'aménager votre poste de travail et vous guide sur votre positionnement 
                        afin de prévénir les douleurs.</p>
                </div>
                <div class="card">
                    <img src="../assets/images/veillir.jpg" alt="travail">
                    <h3>Vieillir en bonne santé</h3>
                    <p>Face au vieillissement, la chiropraxie apporte des réponses préventives et 
                        curatives spécifiques. Le chiropracteur développe un plan de traitement adapté 
                        au degré d'ostéoporose ou d'arthrose de chacun permettant l'amélioration de la 
                        qualité de vie des séniors.</p>
                </div>
                <div class="card">
                    <img src="../assets/images/pediatrique.jpg" alt="travail">
                    <h3>La chiropraxie pédiatrique</h3>
                    <p>Colique, torticois congénital pour le nouveau né en passant par la scoliose
                        qui peut débuter à l'adolescence, grandir n'est pas chose facile ! Le chiropracteur
                            adopte ses techniques aux besoins spécifiques et au développement de l'enfant.</p>
                </div>
                <div class="card">
                    <img src="../assets/images/grossesse.jpg" alt="travail">
                    <h3>Le suivi de grossesse</h3>
                    <p>La grossesse est une période importante de changements posturaux, 
                        hormonaux et physiologiques. La chiropraxie est une thérapie naturelle
                        et sans contre indication, elle constitue un atout pour le suivi et le 
                        soin de la femme enceinte.</p>
                </div>
            </div>
        </div>
        
    </div>
    <div class="w-screen flex flex-row justify-center items-center panel">
        <div class="w-full md:w-2/3 lg:w-2/3 mx-9 my-20 text-white">
            <h2 class="font-bold text-2xl" id="text-color">Les motifs de consultations sont divers</h2>
            <ul class="text-sm">
            <li>Douleurs cervicales, torticolis, névralgie cervico-brachiale, lumbago, 
                sciatique, hernie discale, douleurs thoraciques</li>
            <li>Douleurs articulaires et musculaires (mâchoire, épaule, coude, poignet, 
                hanche, genou, cheville) telles que le canal carpien, entorse, épicondylite, 
                syndrome de la bandelette ilio-tibiale</li>
            <li>Maux de tête, vertiges, troubles du sommeil</li>
            <li>Préparation de compétition sportive, accompagnement de grossesse, ...</li>
            </ul>
            <p class="text-sm">Mais également dans le cadre de la PREVENTION !</p>
            <button-view/>
        </div>
    </div>
    <div class="w-screen flex justify-center items-center flex-col py-12">
        <h2 class="text-3xl font-bold" style="font-family: 'Satisfy', cursive;">La chiropraxie en video</h2>
        <div class="flex w-full h-96 flex-col md:flex-row lg:flex-row justify-center items-center mt-5">
            <iframe src="https://www.youtube.com/embed/Oi-KG_bILXg" frameborder="0" allowfullscreen></iframe>
            <iframe src="https://www.youtube.com/embed/SeYb1Kklqu0" frameborder="0" allowfullscreen></iframe>
        </div>
    </div>
</template>

<script>
import ButtonView from '../components/ButtonView.vue'

export default {
    name: "home-view",
    components: {
        ButtonView
    }
}
</script>

<style scoped>
    .card{
        @apply w-full md:w-1/3 lg:w-1/3 h-full p-5 rounded-lg;
    }

    .card h3{
        @apply text-lg font-semibold my-3;
    }

    .card p{
        @apply text-sm my-3 pb-3;
    }

    .card img {
        width: 100%;
        height: 200px;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .fond {
        background-image: url('../assets/images/fonds/chiropractic.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .panel {
        background-image: url('../assets/images/fonds/consul.jpg');
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
        position: relative;
        z-index: 11;
        overflow: hidden;
        border-left: 0px;
        border-right: 0px;
        background-position: 50% 29px;
    }

    iframe{
        @apply w-11/12 md:w-1/2 lg:w-1/2 h-full m-3;
    }

    h2 {
        font-family: 'Satisfy', cursive;
        color: #0787B8;
    }

    #text-color{
        color: white;
    }
</style>