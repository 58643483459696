<template>
    <div class="p-5 background-blue text-white">
        <div class="flex flex-row justify-between">
            <div>
                <p class="mb-2 text-xs md:text-base lg:text-base"><font-awesome-icon icon="home"/> Laëtitia TAN Chiropracteur<br/>
                    56 avenue du Maréchal de Lattre de Tassigny<br/>
                    94410 Saint Maurice
                </p>
                <a class="text-blue-300 text-xs md:text-base lg:text-base" href="mailto:laetitiatan.chiro@gmail.com"><font-awesome-icon icon="envelope"/> laetitiatan.chiro@gmail.com</a>
            </div>
            <p class="text-xs md:text-base lg:text-base"><font-awesome-icon icon="phone"/> Sur Rendez-vous au 06 63 40 79 60</p>
        </div>
        <p class="text-center text-xs md:text-base lg:text-base mt-3"><a href="https://lenaclavier.com" target="_blank">Copyright © Léna Clavier 2021</a></p>
    </div>
</template>

<script>
export default {
    name: "footer-view"
}
</script>
