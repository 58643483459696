<template>
  <nav-bar/>
  <router-view></router-view>
  <footer-view/>
</template>

<script>
import FooterView from './components/FooterView.vue'
import NavBar from './components/nav/NavBar.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    FooterView
  }
}
</script>
