<template>
    <button>
        <a href="https://www.doctolib.fr/chiropracteur/saint-maurice/laetitia-tan" target="_blank">
            PRENDRE RENDEZ-VOUS
        </a>
    </button>
</template>

<script>
export default {
    name: "button-view",
}
</script>

<style scoped>
    button{
        @apply bg-white border-2 hover:text-white p-2 my-3 rounded-lg
    }

    button{
        border-color: #0787B8;
        color: #0787B8;
        transition: background-color 1s;
    }
    button:hover{
        background-color: #0787B8;
    }
</style>