<template>
    <div class="h-auto md:h-screen lg:h-screen flex justify-center items-center flex-col panel text-white py-12">
        <h1 class="font-bold text-2xl md:text-3xl lg:text-3xl mt-20">Comment se déroule une consultation ?</h1>
        <ol class="w-10/12 text-xs md:text-base lg:text-base">
            <li>Anamnèse : le chiropracteur commence par vous interroger sur les raisons de votre consultation, sur vos 
                antécédents médicaux et sur votre santé, afin de me mieux déceler l’origine de votre trouble.<br><br></li>
            <li>Examen : le chiropracteur effectue un examen physique complet en analysant votre posture, en réalisant des 
                tests et un examen chiropratique.<br><br></li>
            <li>Ajustement : le chiropracteur intervient manuellement et réalise des actes de manipulation de la colonne 
                vertébrale et des articulations en exerçant un geste précis et rapide. Il peut aussi avoir recours à des 
                tables et appareils spécialisés.<br><br></li>
        </ol>
        <p class="w-10/12 text-xs md:text-base lg:text-base">Le chiropracteur peut également vous donner des conseils en termes de postures, de nutrition ou d’hygiène de 
            vie ou des exercices à réaliser chez soi de nature à renforcer l’efficacité du traitement.<br>
        A la fin de la 1ere consultation, un diagnostic est posé et vous est expliqué. Le chiropracteur définit son plan 
        de traitement et vous indique le nombre de consultations qu’il juge nécessaire à l’amélioration de votre état.
        Il est également capable de référer son patient à qui de droit s’il estime que cela n’est pas de son ressort.
        En général, la 1ere consultation dure en moyenne 1h, les consultations de suivi sont de 30min.</p>
        <div class="text-center">
            <button-view/>
        </div>
    </div>
    <div class="flex justify-center items-center flex-col py-8">
        <h2 class="font-bold text-2xl my-4 text-center">On note 3 temps dans la prise en charge chiropratique</h2>
        <ul class="w-10/12 text-xs md:text-base lg:text-base">
            <li>- Le temps du soulagement, qui correspond à la période où l'on diminue le message de la douleur, 
                les séances sont généralement rapprochées.<br><br></li>
            <li>- Le temps de la stabilisation, qui ancre les corrections apportées sur le long terme afin d’apporter 
                un équilibre à votre corps de façon durable. Les séances sont moins fréquentes.<br><br></li>
            <li>- Le temps de la prévention, qui est un suivi régulier afin d'entretenir  ce qui a été acquis lors des 
                séances précédentes et qui permet d’éviter les récidives.<br><br></li>
        </ul>
        <p class="w-10/12 text-xs md:text-base lg:text-base">Quel que soit l’âge et la condition physique du patient, le chiropracteur adapte les techniques et le plan de 
            traitement à chaque patient en fonction de ses antécédents et de son mode de vie. La chiropraxie s’adresse à 
            tout le monde de l’enfant au senior en passant par la femme enceinte et le sportif amateur ou professionnel.<br><br></p>
        <strong class="w-10/12 font-thin text-center text-base md:text-xl lg:text-xl">Votre santé est votre plus grande richesse.</strong>
    </div>
    <div class="flex justify-center items-center flex-col p-10">
        <div class="flex justify-center items-center flex-col md:flex-row lg:flex-row">
            <div class="w-11/12 md:w-1/2 lg:w-1/2 ml-0 md:ml-3 lg:ml-3">
                <h2 class="font-bold text-2xl my-4">Qu’est ce que le « crac » ?</h2>
                <p class="text-xs md:text-base lg:text-base">Parfois impressionnant, parfois inexistant,  le «  crac » ne témoigne pas de la qualité d’un ajustement. <br>
                Il s’agit d’une réaction chimique, où le dioxyde de carbone présent dans l’articulation passe de l’état liquide 
                à l’état gazeux (due à la dépression de l’articulation) provoquant ce bruit.<br>
                L’ajustement chiropratique est indolore, ce ne sont pas vos os qui craquent.</p>
            </div>
            <img class="w-full md:w-1/3 lg:w-1/3 mt-5 md:mt-0 lg:mt-5" src="../assets/images/crack.jpg" alt="crack">
        </div>
    </div>
    <div class="flex flex-col w-10/12 mx-auto my-5 justify-center items-center">
        <h2 class="font-bold text-2xl my-4">Tarifs</h2>
        <div class="table-responsive mt-4">
            <table class="table-auto divide-y divide-gray-200">
                <tbody class="bg-white divide-y divide-gray-200 w-full">
                    <tr>
                        <td class="px-6 py-4 whitespace-nowrap">Consultation</td>
                        <td class="px-6 py-4 whitespace-nowrap">60€</td>
                    </tr>
                    <tr>
                        <td class="px-6 py-4 whitespace-nowrap">Nourrisson et enfants de moins de 12 ans</td>
                        <td class="px-6 py-4 whitespace-nowrap">45€</td>
                    </tr>
                    <tr>
                        <td class="px-6 py-4 whitespace-nowrap">Partenariat (entreprise, club sportif)</td>
                        <td class="px-6 py-4 whitespace-nowrap">45€</td>
                    </tr>
                    <!-- <tr>
                        <td class="px-6 py-4 whitespace-nowrap">Consultation à domicile</td>
                        <td class="px-6 py-4 whitespace-nowrap">80€</td>
                    </tr> -->
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import ButtonView from '../components/ButtonView.vue'
export default {
    name: "consultation-view",
    components: {
        ButtonView
        
    },
}
</script>

<style scoped>
    @media screen and (max-width: 767px){
        .table-responsive {
            width: 100%;
            margin-bottom: 15px;
            overflow-y: hidden;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            border: 1px solid #ddd;
        }
    }
    .panel {
        background-image: url('../assets/images/fonds/consultation.jpg');
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
        z-index: 11;
        overflow: hidden;
        border-left: 0px;
        border-right: 0px;
        background-position: 50% 29px;
        padding-top: 50px;
    }

    h2 {
        font-family: 'Satisfy', cursive;
        color: #0787B8;
    }
</style>