<template>
    <nav class="flex items-center justify-between bg-white flex-wrap p-3 w-full z-10 absolute top-0">
		<router-link to="/" class="no-underline">
			<div class="flex flex-row items-center">
				<img class="w-8" src="../../assets/images/logo.png" alt="logo">
				<h1 style="font-family: 'Open Sans', sans-serif;">Cabinet Chiropratique<br/>Laëtitia TAN</h1>
			</div>
		</router-link>

		<div class="block lg:hidden" 
            v-on:click="showMobileMenu = !showMobileMenu">
			<button id="nav-toggle" class="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-500 hover:border-gray-500">
				<svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
			</button>
		</div>

		<div class="w-full flex-grow lg:flex lg:items-center lg:w-auto lg:block pt-6 lg:pt-0" id="nav-content" v-bind:class="{ block: showMobileMenu, hidden: !showMobileMenu}">
			<ul class="list-reset lg:flex justify-end flex-1 items-center">
				<li class="mr-3">
                    <router-link to="/">Accueil</router-link>
				</li>
				<li class="mr-3">
                    <router-link to="/chiropraxie">La chiropraxie</router-link>
				</li>
				<li class="mr-3">
                     <router-link to="/chiropracteur">Votre chiropracteur</router-link>
				</li>
				<li class="mr-3">
                    <router-link to="/consultation">La consultation</router-link>
				</li>
                <li class="mr-3">
                    <router-link to="/contact">Contact</router-link>
				</li>
			</ul>
			<button>
				<a href="https://www.doctolib.fr/chiropracteur/saint-maurice/laetitia-tan" target="_blank">
					Réserver une consultation
				</a>
			</button>
		</div>
	</nav>
</template>

<script>
export default {
    name: "nav-bar",
    data() {
        return{
            showMobileMenu: false
        }
    }
}
</script>

<style scoped>
	li{
		@apply py-3;
	}

	li a{
		@apply font-thin;
	}

	li a {
		text-decoration-color: #0787B8;
	}

	button{
        @apply hover:bg-white border-2 text-white p-2 my-3 rounded-lg
    }

    button{
		border-color: #0787B8;
		background-color: #0787B8;
		transition: background-color 1s;
    }
    button:hover{
        color: #0787B8;
    }
</style>
