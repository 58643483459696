<template>
    <div class="w-screen map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2626.8737938661293!2d2.4178358155685085!3d48.82246941118944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e672fad23aa3cf%3A0x16bfa6f92b0412f7!2sLa%C3%ABtitia%20TAN%20-%20Chiropracteur!5e0!3m2!1sfr!2sfr!4v1614252355304!5m2!1sfr!2sfr" class="w-full h-96" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
    <div>
        <div class="w-full md:w-4/5 lg:w-4/5 flex justify-center items-center flex-col text-center bg-white m-auto relative bottom-0 md:bottom-20 lg:bottom-20">
            <h1 class="font-bold text-3xl my-4">Contact</h1>
            <div class="w-full flex flex-col md:flex-row lg:flex-row justify-center items-start my-4 text-xs md:text-base lg:text-base flex-wrap">
                <div class="w-full md:w-1/3 lg:w-1/3">
                    <h2>Téléphone & Email</h2>
                    <p>06 63 40 79 60</p>
                    <a href="mailto:laetitiatan.chiro@gmail.com">laetitiatan.chiro@gmail.com</a>
                </div>
                <div class="w-full md:w-1/3 lg:w-1/3">
                    <h2 class="lg:mt-0 md:mt-0 mt-4">Adresse</h2>
                    <p>Laëtitia TAN Chiropracteur<br/>
                        56 avenue du Maréchal de Lattre de Tassigny<br/>
                        94410 Saint Maurice
                    </p>
                    <h2 class="mt-4">Moyens de transport</h2>
                    <ul>
                        <li>En métro : ligne 8 à l'arret Charenton Ecoles</li>
                        <li>En bus : 325, 24, 111 et 180</li>
                        <li>Par l’autoroute A4 prendre la sortie Saint Maurice</li>
                    </ul>
                </div>
                <div class="w-full md:w-1/3 lg:w-1/3">
                    <h2 class="lg:mt-0 md:mt-0 mt-4">Horaires</h2>
                    <ul>
                        <li>Lundi : 14:00 - 20:00</li>
                        <li>Mardi : 09:00 - 20:00</li>
                        <li>Mercredi : 09:00 - 20:00</li>
                        <li>Jeudi : 09:00 - 20:00</li>
                        <li>Vendredi : 09:00 - 20:00</li>
                        <li>Samedi : 09:00 - 13:00</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
export default {
    name: "contact-view",
    components: {
        
    },
}
</script>

<style scoped>
    h2{
        @apply font-semibold
    }

    h2, h1{
        color: #0787B8;
    }

    ul li, p, a{
        @apply text-sm;
    }

    .map{
        padding-top: 110px;
    }
</style>