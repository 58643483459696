import { createRouter, createWebHistory } from 'vue-router'

import HomeView from './views/HomeView.vue'
import ChiropraxieView from './views/ChiropraxieView.vue'
import ChiropracteurView from './views/ChiropracteurView.vue'
import ConsultationView from './views/ConsultationView.vue'
import ContactView from './views/ContactView.vue'

const routes = [
  { path: '/', component: HomeView },
  { path: '/chiropraxie', component: ChiropraxieView },
  { path: '/chiropracteur', component: ChiropracteurView },
  { path: '/consultation', component: ConsultationView },
  { path: '/contact', component: ContactView },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'underline font-bold'
})

export default router