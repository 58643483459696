<template>
    <div class="h-auto md:h-screen lg:h-screen flex justify-center items-center flex-col py-10">
        <div class="flex justify-center items-center w-10/12 flex-col md:flex-row lg:flex-row mt-28">
            <img class="h-auto md:h-1/2 lg:h-1/2 w-1/2 md:w-auto lg:w-auto m-auto rounded-full" src="../assets/images/photo.jpg" alt="definition">
            <div class="w-11/12 md:w-1/2 lg:w-1/2 mr-3">
                <h1 class="font-bold text-3xl my-4 text-center">Votre chiroprateur Saint Maurice</h1>
                <h2 class="font-bold text-3xl my-4 text-center">Laëtitia TAN</h2>
                <p class="text-sm text-center">Le titre de Chiropracteur s’obtient après 6 années d’études soit 5500 heures 
                de cours pratiques et théoriques, le cursus se termine par un internat en clinique de 
                15 mois (400 visites à effectuer ainsi qu’une dizaine de stages d’observations chez des 
                chiropracteurs expérimentés). <br>
                Au cours de ces 6 années, des stages en milieu hospitalier sont effectués en service d’orthopédie, 
                neurologie et radiologie ainsi qu’un stage de 150H en dissection humaine.</p>
                <h3 class="text-base text-center my-4">Mon parcours</h3>
                <ul class="text-sm text-left">
                    <li>- Séminaires « SOT » (partie 1 et 2) en 2017<br><br></li>
                    <li>- Membre de l’European Chiropractor’s Union en 2016 <br><br></li>
                    <li>- Diplômée de l’European Academy of Chiropractic en 2016 <br><br></li>
                    <li>- Diplômée de l’Institut Franco-Européen de Chiropraxie en 2016<br><br></li>
                    <li>- Professeur assistante en stage de dissection en 2015<br><br></li>
                </ul>
                <div class="text-center">
                    <button-view/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonView from '../components/ButtonView.vue'
export default {
    name: "chiropracteur-view",
    components: {
        ButtonView
        
    },
}
</script>

<style scoped>
    h1{
        color: #0787B8;
    }
</style>