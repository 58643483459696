<template>
    <div class="flex justify-center items-center flex-col block">
        <div class="flex justify-center items-center flex-col md:flex-row lg:flex-row my-7">
            <img class="w-3/12 h-auto m-auto hidden md:block lg:block" src="../assets/images/definition.jpg" alt="definition">
            <div class="w-11/12 md:w-1/2 lg:w-1/2 mx-4">
                <h2 class="text-3xl font-bold my-3">Définition</h2>
                <p class="text-sm">Fondée en 1895 par Daniel David Palmer aux États Unis, elle est la médecine 
                    manuelle de référence pour les soins du dos et des articulations.<br><br>
                La chiropraxie a pour objet la détection, la correction et la prévention des 
                dysfonctionnements neuro-musculo-squelettiques et de ses conséquences, 
                notamment au niveau de la colonne vertébrale et des membres.<br><br>
                Ces troubles neuro-musculo-squelettiques se traduisent par des contractures, 
                des douleurs ou encore une limitation de la mobilité.<br><br>
                La chiropraxie est une thérapie naturelle (non médicamenteuse), reconnue en 
                France par le code de la Santé Publique comme par la communauté médicale.</p>
            </div>
        </div>
    </div>
    <div class="w-screen flex flex-row justify-center items-center panel">
        <div class="w-11/12 md:w-2/3 lg:w-2/3 mx-9 text-white mx-9 my-20 ">
            <h2 class="text-3xl font-bold" id="text-color">Actions et bienfaits</h2>
            <p class="text-sm md:text-base lg:text-base">Le système nerveux contrôle l’ensemble des fonctions de notre corps. 
            La chiropraxie, au moyen d’ajustements sur la colonne vertébrale, agit sur le système 
            nerveux afin de libérer toute interférence pouvant perturber l'équilibre du corps. Elle 
            restaure ainsi un bon influx nerveux en redonnant la mobilité à chaque articulation 
            vertébrale et périphérique afin d’améliorer la qualité de vie.<br>
            Votre chiropracteur soulage les douleurs corporelles d’origine mécanique et les douleurs 
            d’origine émotionnelle.<br><br>
            La chiropraxie optimise les facultés de réparation du corps humain et vise à équilibrer 
            l’organisme de façon durable.<br><br>
            Consulter un chiropracteur permet de faire un bilan vertébral (nerveux, musculaire, articulaire) 
            afin d’entreprendre des soins mais aussi de manière préventive.
            Un geste de prévention vaut des milliers d’efforts pour la guérison.</p>
        </div>
    </div>
    <div class="flex justify-center items-center flex-col">
        <div class="flex justify-center items-center flex-col md:flex-row lg:flex-row my-7">
            <div class="w-11/12 md:w-1/2 lg:w-1/2 mx-4">
                <h2 class="text-3xl font-bold my-3">Champ d’application</h2>
                <p>De nombreuses études médicales ont démontré l’efficacité des soins en chiropraxie.<br><br>
                Les troubles neuro-musculo-squelettiques regroupent différentes pathologies telles que : 
                cervicalgie, torticolis, vertiges, migraine, trouble de la mâchoire, dorsalgie, lombalgie, 
                lumbago, sciatique, cruralgie, scoliose chez les adolescents, colique et plagiocéphalie positionnel 
                chez les nourrissons, tendinite, problèmes d’épaule, canal carpien ou canal de Guyon et bien d’autres 
                choses encore.<br><br>
                « Votre corps est précieux. Il est votre véhicule pour l’éveil. Prenez en soin ! » -Bouddha</p>
            </div>
            <img class="w-1/2 md:w-1/3 lg:w-1/3" src="../assets/images/fonds/application.jpg" alt="application">
        </div>
    </div>
    <div class="w-screen h-screen flex justify-center items-center flex-col">
        <h2 class="text-3xl font-bold w-11/12 text-center">Différences entre chiropracteur et ostéopathe</h2>
        <div class="table-responsive mt-4">
            <table class="table-auto divide-y divide-gray-200">
                <thead class="bg-gray-50">
                    <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ACTES</th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">CHIROPRACTEURS</th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">OSTEOPATHES</th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr>
                        <td class="px-6 py-4 whitespace-nowrap">Mobilisations</td>
                        <td class="px-6 py-4 whitespace-nowrap">AUTORISÉS</td>
                        <td class="px-6 py-4 whitespace-nowrap">AUTORISÉS</td>
                    </tr>
                    <tr>
                        <td class="px-6 py-4 whitespace-nowrap">Manipulations non forcées</td>
                        <td class="px-6 py-4 whitespace-nowrap">AUTORISÉS</td>
                        <td class="px-6 py-4 whitespace-nowrap">AUTORISÉS</td>
                    </tr>
                    <tr>
                        <td class="px-6 py-4 whitespace-nowrap">Manipulations avec vecteur de force</td>
                        <td class="px-6 py-4 whitespace-nowrap">AUTORISÉS</td>
                        <td class="px-6 py-4 whitespace-nowrap">INTERDITS</td>
                    </tr>
                    <tr>
                        <td class="px-6 py-4 whitespace-nowrap">Mobilisations et manipulations instrumentales</td>
                        <td class="px-6 py-4 whitespace-nowrap">AUTORISÉS</td>
                        <td class="px-6 py-4 whitespace-nowrap">INTERDITS</td>
                    </tr>
                    <tr>
                        <td class="px-6 py-4 whitespace-nowrap">Mobilisations et manipulations mécaniquement assistées</td>
                        <td class="px-6 py-4 whitespace-nowrap">AUTORISÉS</td>
                        <td class="px-6 py-4 whitespace-nowrap">INTERDITS</td>
                    </tr>
                    <tr>
                        <td class="px-6 py-4 whitespace-nowrap">Manipulations non forcées des cervicales</td>
                        <td class="px-6 py-4 whitespace-nowrap">AUTORISÉS SANS AVIS MÉDICAL</td>
                        <td class="px-6 py-4 whitespace-nowrap">UNIQUEMENT SOUS AVIS MÉDICAL</td>
                    </tr>
                    <tr>
                        <td class="px-6 py-4 whitespace-nowrap">Manipulations avec vecteur de force ou instrumentales des cervicales</td>
                        <td class="px-6 py-4 whitespace-nowrap">AUTORISÉS</td>
                        <td class="px-6 py-4 whitespace-nowrap">INTERDITS</td>
                    </tr>
                    <tr>
                        <td class="px-6 py-4 whitespace-nowrap">Possibilité de donner des conseils à visée antalgique</td>
                        <td class="px-6 py-4 whitespace-nowrap">AUTORISÉS</td>
                        <td class="px-6 py-4 whitespace-nowrap">INTERDITS</td>
                    </tr>
                    <tr>
                        <td class="px-6 py-4 whitespace-nowrap">Traitement des troubles fonctionnels</td>
                        <td class="px-6 py-4 whitespace-nowrap">AUTORISÉS</td>
                        <td class="px-6 py-4 whitespace-nowrap">AUTORISÉS</td>
                    </tr>
                    <tr>
                        <td class="px-6 py-4 whitespace-nowrap">Traitement des troubles de l’appareil locomoteur et de leurs conséquences</td>
                        <td class="px-6 py-4 whitespace-nowrap">AUTORISÉS</td>
                        <td class="px-6 py-4 whitespace-nowrap">INTERDITS</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: "chiropraxie-view",
    components: {
        
    },
}
</script>

<style scoped>
    @media screen and (max-width: 767px){
        .table-responsive {
            width: 100%;
            margin-bottom: 15px;
            overflow-y: hidden;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            border: 1px solid #ddd;
        }
    }

    td{
        @apply text-sm;
    }
    .panel {
        background-image: url('../assets/images/fonds/actions.jpg');
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
        position: relative;
        z-index: 11;
        overflow: hidden;
        border-left: 0px;
        border-right: 0px;
        background-position: 50% 29px;
    }

    .block{
        padding-top: 100px;
        height: auto;
    }
    h2 {
        font-family: 'Satisfy', cursive;
        color: #0787B8;
    }

    #text-color{
        color: white;
    }
</style>